.fallback-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: rgba(255, 255, 255, 0.8); 
  }
  .fallback-loader-dots{
    display: flex;
    gap: 8px;
  }
  
  .fallback-loader-dots div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #1EB858;
    animation: bounce 1.2s infinite ease-in-out;
  }
  
  .fallback-loader-dots div:nth-child(1) {
    animation-delay: -0.4s;
    background-color: #1EB858; 
  }
  
  .fallback-loader-dots div:nth-child(2) {
    animation-delay: -0.2s;
    background-color: #00623D; 
  }
  
  .fallback-loader-dots div:nth-child(3) {
    animation-delay: 0s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
    
  