@font-face {
    font-family: 'Helvetica';
    src: url('./Helvetica.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Bold';
    src: url('./Helvetica-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica BoldOblique';
    src: url('./Helvetica-BoldOblique.ttf') format('truetype');
    font-weight: 700;
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Compressed';
    src: url('./helvetica-compressed.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Light';
    src: url('./helvetica-light.ttf') format('truetype');
    font-weight: 300; 
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Oblique';
    src: url('./Helvetica-Oblique.ttf') format('truetype');
    font-weight: 400; 
    font-style: oblique;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Rounded Bold';
    src: url('./helvetica-rounded-bold.otf') format('opentype');
    font-weight: 700; 
    font-style: normal;
    font-display: swap;
}