@import '../src/assets/fonts/fonts.css';
*{
    user-select: none;
    box-sizing: border-box;
    }

:root {
    --color-black: #0B0F14;
    --color-white: #FFFFFF;
    --color-primary : #1EB858; 
    --color-gray-light: #C8CACC; 
    --color-gray-lighter: #EAEAEA; 
    --color-green-muted: #CEFECE; 
    --color-green-bright: #00D45B; 
    --color-green-dark: #15603F; 
    --color-logo-txt:#1DAB61;
    --color-white-transparent: #D8FDD240; 
    --color-gray-muted: #5A6265; 
    --color-gray-dark: #707070;
    --color-blue-primary: #027EF2; 
    --color-text-dark: #0A0A0A; 
    --color-text-darker: #14181C; 
    --color-text-muted: #797C7E;
    --color-overlay-dark: #0A0A0A80; 
    --color-dark-secondary:#2C3034;
  } 


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
 
}


ul{list-style-type:none;padding:0!important;margin:0!important}
h1,
h2,
h3, h4, h5, h6{margin: 0;}

p{margin:0 !important}

img{
  display: block;
  line-height: 0
}




.animation-streming{
width: 60px;
height: 60px;
}