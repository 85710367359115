.toast-outer{
    position: absolute;
    top: 10px;
    right: 0;
    background-color:#1EB858;
    padding: 10px 15px;
    border-radius: 13px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.6);
    z-index: 999;
    left: 0;
    width: 100%;
    max-width: 350px;
    margin: 0px auto;
}

.toast-inner{
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
}

.toast-image-outer{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid var(--color-white);
}

.toast-image-outer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%
}

.toast-right-sec-outer {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-left: 5px;
}

.host-name-outer{
    display: flex;
    align-items: start;
    margin: 8px 0px;
    gap: 8px;
    position: relative;
}

.status-indicator {
    flex-shrink: 0;
    position: absolute;
}

.status-indicator p {
    font-family: "Helvetica";
    color: var(--color-white);
    font-size: 8px;
    text-transform: capitalize;
}

.host-name-outer p{
    font-size: 16px;
    color:var(--color-white);
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Helvetica Bold";
    text-transform: capitalize;
}

.text-message-outer p {
    font-size: 14px;
    color:var(--color-white);
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Helvetica";
    text-transform: capitalize;
}

.reply-button{
    padding: 5px 15px;
    border-radius: 12px;
    width: 66px;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;

}
.reply-button p{
    font-size: 12px;
    color: #00623D;
    font-family: 'Helvetica';
}
