 /* .popup-overlay-pwa-installation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 25px;
} */

.popup-overlay-pwa-installation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

/*.popup-box-pwa-installation {
   background: #fff;
   padding: 30px 51px;
   width: 100%;
   max-width: 312px;
   height: auto;
   border-radius: 16px;
   box-shadow: 0px 0px 6px #00000029;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: column;
   position: relative;
}

.popup-close-icon-outer {
   position: absolute;
   height: 30px;
   width: 30px;
   top: 10px;
   right: 10px;

}

.popup-close-icon-outer img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.popup-box-pwa-installation-top-image {
   width: 100%;
   height: 77px;
   max-width: 53px;
   margin-bottom: 20px;
}

.popup-box-pwa-installation-top-image img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.popup-text-outer-pwa-installation {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   line-height: 24px;
   margin-bottom: 15px;
}

.button-group-pwa-installation {
   width: 100%;
   margin-top: auto;
   display: flex;
   align-items: center;
   justify-content: center;
}

.confirm-button-pwa-installation {
   width: 100%;
   height: 40px;
   background: linear-gradient(90deg, #1EB858, #1EB858);
   border-radius: 25px;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

.confirm-button-pwa-installing {
   width: 100%;
   height: 40px;
   background: linear-gradient(90deg, #1EB858, #1EB858);
   border-radius: 25px;
   border: none;
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
   max-width: 183px;
   padding: 10px 30px;
}

.popup-text-pwa-installation {
   font-family: 'Helvetica';
   font-size: 17px;
   text-align: center;
   margin-bottom: 20px;
   color:#3B2422 ;
}

.confirm-text-pwa-installation {
   color: #FFF;
   font-size: 22px;
   font-family: 'Helvetica';
}

.animation-installing {
   width: 35px;
   height: 35px;
} */


.wrapper {
    width: 100%;
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
    height: 100vh;
    overflow-y: auto;
}

a {
    text-decoration: none !important;
}

.app-logo {
    width: 88px;
    height: 72px;
    object-fit: cover;
    border-radius: 20px;
    margin: 7px 7px 0 0;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.header {
    display: flex;
    gap: 0.6rem;
    margin: 1rem;
}

.app-name {
    font-size: 1.5rem;
    letter-spacing: 0;
    word-break: keep-all;
    line-height: 2rem;
}

.app-info_main {
    letter-spacing: .00625em;
    font-weight: 500;
    display: flex;
    color: #01875f;
    line-height: 1.5rem;
    font-size: 1rem;
    flex-wrap: wrap;
    white-space: nowrap;
    margin-top: 4px;
}

.app-info_block {
    font-size: 13px;
    display: flex;
    flex-direction: column;
}

.add-info_block {
    display: flex;
    justify-content: flex-start;
    margin: 1rem;
    padding: 16px 0;
}

.info {
    width: 33.3333%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info_first {
    border-right: 1px solid #d8d8dc;
}

.info_second {
    border-right: 1px solid #d8d8dc;
}

.info span {
    font-weight: 600;
    height: 24px;
    display: flex;
    align-items: center;
}

.info p {
    color: rgb(95, 99, 104);
    font-size: 12px;
    margin-top: 4px;
}

.info span img {
    height: 12px;
    width: 12px;
}

.pegi {
    width: 24px;
}

.rate-block {
    display: flex;
    flex-wrap: wrap;
}

.rate {
    white-space: nowrap;
    display: flex;
}

.download-amount {
    color: #616161;
    margin-left: 3px;
}

.user-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 3px;
}

.star {
    width: 10px;
    height: 10px;
}

.actions-block {
    margin: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.install-btn {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: #01875f;
    border-radius: 8px;
    color: white;
    font-weight: 700;
}

.wishlist-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    outline: 0;
    margin: 0 auto;
    width: fit-content;
    border: 0;
    background-color: transparent;
    color: #01875f;
    font-weight: 700;
    font-size: 14px;
    gap: 0.4rem;
}

.wishlist-btn img {
    width: 24px;
    height: 24px;
}

.ad-contains {
    color: #616161;
    font-size: 13px;
    font-weight: 400;
}

.app-photo_block {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
}

.app-photo,
video.preview-video {
    border-width: 0;
    object-fit: cover;
    width: 124px;
    min-width: 124px;
    height: 220px;
    margin-left: 0.5rem;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

 /* staging  */

/* .preview-video {
    border-width: 0;
    object-fit: cover;
    width: 124px;
    min-width: 124px;
    height: 220px;
    margin-left: 0.5rem;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
} */

.about {
    padding: 20px 1rem;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.arrow {
    width: 20px;
    height: 20px;
}

.app-desc {
    font-weight: 400;
    letter-spacing: .0142857143em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: .875rem;
    padding: 0 1rem 1rem 1rem;
    line-height: 1.25rem;
    color: rgb(95, 99, 104);
}

.up-block {
    padding: 1rem 1rem;
}

.up-block_title {
    color: #202124;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .0178571429em;
}

.up-block_desc {
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
    margin-top: 4px;
    color: rgb(95, 99, 104);
}

.data-block {
    padding: 1rem 1rem 0 1rem;
}

.data-block_title {
    color: #202124;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .0178571429em;
}

.data-block_desc {
    margin-top: 20px;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
    color: rgb(95, 99, 104);
}

.reviews-block {
    padding: 1rem;
}

.reviews-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.reviews-header_name {
    float: left;
    color: #333;
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    width: fit-content;
}

.verified-block {
    margin: 20px 0;
    font-size: .75rem;
    font-weight: 400;
    color: rgb(95, 99, 104);
    display: flex;
    align-items: center;
    gap: 8px;
}

.policy-block {
    display: flex;
    align-items: center;
}

.star-main {
    width: 16px;
    height: 16px;
}

.rate-big {
    color: #202124;
    font-size: 64px;
    font-weight: 400;
}

.rate-blocks {
    display: flex;
}

.rate_first-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: fit-content;
}

.rate_second-block {
    width: 100%;
    max-width: 260px;
    padding: 30px 16px 30px;
    display: inline-block;
}

.rate-total {
    font-size: 12px;
    margin-top: 1rem;
    white-space: nowrap;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: rgb(95, 99, 104);
}

.user-second {
    vertical-align: middle !important;
    margin-bottom: 2px;
}

.rate-num {
    font-size: 11px;
    left: -16px;
    letter-spacing: 1px;
    text-align: left;
    color: #737373;
    font-weight: 400;
    position: absolute;
}

.rate-line {
    display: inline-block;
    max-width: 120px;
}

.rate-line5 {
    width: 80%;
    height: 100%;
    background-color: rgb(1, 135, 95);
    opacity: .8;
    border-radius: 9999px;
}

.rate-line4 {
    width: 40%;
    height: 100%;
    background-color: rgb(1, 135, 95);
    opacity: .8;
    border-radius: 9999px;
}

.rate-line3 {
    width: 4%;
    height: 100%;
    background-color: rgb(1, 135, 95);
    opacity: .8;
    border-radius: 9999px;
}

.rate-line_block {
    position: relative;
    height: 10px;
    margin-bottom: 6px;
    width: 100%;
    background-color: rgb(232, 234, 237);
    font-weight: 400;
    display: flex;
    border-radius: 9999px;
}

.comment-author_rate {
    display: flex;
    align-items: center;
    gap: 4px;
}

.comment-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.photo_undefined {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #9ace6a;
    color: white;
    font-size: 16px;
    user-select: none;
    margin-right: 0.5rem;
}

.user-photo_block {
    display: flex;
    align-items: center;
}

.photo-block {
    height: 48px;
    display: flex;
    align-items: center;
}

.photo-block img {
    margin-right: 0.5rem;
}

.user-photo {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    user-select: none;
}


.comment-author {
    line-height: 24px;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.comment-author_name {
    font-size: 14px;
    color: #333;
}

.star-comment {
    width: 12px;
    height: 12px;
}

.comment-author_time {
    color: #5f6368;
    display: inline-block;
    font-size: .75rem;
    font-weight: normal;
    letter-spacing: .025em;
    margin-left: 1ch;
    vertical-align: middle;
    white-space: nowrap;
}

.comment-text {
    word-wrap: break-word;
    hyphens: auto;
    font-size: 14px;
    line-height: 1.4;
    color: #333;
    font-weight: 300;
}

.preview-image {
    border-width: 0;
    object-fit: cover;
    width: 124px;
    min-width: 124px;
    height: 220px;
    margin-left: 0.5rem;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
}