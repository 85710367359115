@media (min-width: 768px) {
    .hostUnavailable-overlay {
        border: 2px solid var(--color-primary); 
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 430px; 
        margin:0 auto
    }
}

.hostUnavailable-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 25px;
}

.hostUnavailable-box {
    width: 100%;
    background: #fff;
    max-width: 400px;
    height: auto;
    border-radius: 16px;
    box-shadow: 0px 0px 6px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 125px 0px 22px;
    position: relative;
    z-index: 9;
}

.hostUnavailable-text-outer {
    width: 100%;
    max-width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hostUnavailable-button{
    width: 100%;
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 135px;
    background-color: #1EB858;
    border-radius: 23px;
    padding: 10px 0px;
}

.hostUnavailable-button p{
    font-size: 22px;
    color: var(--color-white);
    font-family:'Helvetica';
}

.hostUnavailable-text{
    font-size: 26px;
    font-family: 'Helvetica Bold';
    color: #3B2422;

}
.hostUnavailable-text-info{
    font-size: 24px;
    font-family: 'Helvetica';
    color: #3B2422;
    text-align: center;

}

.hostUnavailable-top-animation{
    position: absolute;
    height: 150px;
    width: 150px;
    top: -10px;
}

