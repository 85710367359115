@media (min-width: 768px) {
    .splash-outer {
        border: 2px solid var(--color-primary); 
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 430px; 
        margin:0 auto;
        height: calc(var(--vh, 1vh) * 100);
    }
}


.splash-outer{
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
}

.splash-inner{
    height: 200px;
    width: 200px;
    background-image: url('../../../assets/images/splashScreenWhatsappVideos.webp');
    background-position: center;
    background-size: cover;
    animation: slideFromTop 1.5s ease-in-out forwards;
}

@keyframes slideFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0; 
    }
    100% {
      transform: translateY(0); 
      opacity: 1;
    }
  }